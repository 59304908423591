<template>
	<!-- 产品- 提需求 -->
	<div>
		<GModule class="container">
			<GTitle name="提需求" style="margin: 20px;"></GTitle>
			<a-form class="need-box"
			    :model="formState"
			    name="basic"
			    :label-col="{ span: 7}"
			    :wrapper-col="{ span: 12 }"
			    autocomplete="off"
					:scrollToFirstError='true'
			    @finish="onFinish">
			    <a-form-item 
					label="需求类型" 	
					name="type" 
					:colon= "false"
					:rules="[{ required: false, message: '请选择需求类型' }]">
			        <a-dropdown>
			            <template #overlay>
			                <a-menu selectable @click="handleMenuClick">
			        			<a-menu-item v-for="(pitem,pindex) in productArr" :key="pindex">
											{{pitem.name}}
										</a-menu-item>
			                </a-menu>
			            </template>
			            <a-button v-if="productArr.length > 0">
			        		{{productArr[stateIndex].name}}
			                <DownOutlined />
			            </a-button>
			        </a-dropdown>
			    </a-form-item>
				<a-form-item
					label="优先级" 	
					name="type" 
					:colon= "false"
					:rules="[{ required: false, message: '请选择优先级' }]">
				    <a-dropdown>
				        <template #overlay>
				            <a-menu selectable @click="handleLevelClick">
				    			<a-menu-item v-for="(itm,ind) in levelArr" :key="ind">{{itm}}</a-menu-item>
				            </a-menu>
				        </template>
				        <a-button>
				    		{{levelArr[levelIndex]}}
				            <DownOutlined />
				        </a-button>
				    </a-dropdown>
				</a-form-item>
				<a-form-item label="需求模块" :autoLink='false' :colon= "false">
					<div v-if="!mId" style="color:#999;">请在左侧模块菜单选择需求点（第三级）</div>
					<a-breadcrumb v-else separator=">">
					    <a-breadcrumb-item v-for="(item,index) in selectedMemu">{{item.name}}</a-breadcrumb-item>
					 </a-breadcrumb>
				</a-form-item>
				<a-form-item 
					label="需求描述" 
					name="describe" 
					:colon= "false" 
					:rules="[{ required: true, message: '请输入描述' }]">
				    <a-textarea placeholder="需求描述" style="height: 90px;" v-model:value="formState.describe" />
				</a-form-item>
				<a-form-item
					label="参数/逻辑说明" 
					name="explain" 
					:colon= "false" 
					:rules="[{ required: false, message: '请输入参数/逻辑说明' }]">
				    <a-textarea placeholder="参数/逻辑说明" style="height: 90px;" v-model:value="formState.explain" />
				</a-form-item>
				<a-form-item
					label="需求图片" 
					name="imgs" 
					:colon= "false" 
					:rules="[{ required: false}]">
				    <a-upload
						name="file"
						v-model:file-list="fileList"
						:maxCount='8'
						list-type="picture-card"
						:customRequest ="customRequest"
						@remove="remove">
						<div class="addimg" v-if="fileList.length < 8">
							<plus-outlined />
							<p style="color: #999999;">点击上传凭证</p>
						</div>
				    </a-upload>
				</a-form-item>
				<a-form-item  :wrapper-col="{ offset:10 }">
				  <a-button class="btn clo1" @click="goBack()">返回</a-button>
				  <a-button class="btn clo3" html-type="submit">提交</a-button>
				  <a-button class="btn clo6" @click="cancel()">取消</a-button>
				</a-form-item>
			</a-form>
		</GModule>	
	</div>
</template>

<script>
	export default{
		name:'ProductNeed',
		data(){
			return{
				productArr:[], // 已有需求列表
				stateIndex:0,
				formState :{
					type:'',
					describe:'',
					explain:'',
					imgs:[] // 图片列表
				},
				fileList:[] ,// 上传图片file列表
				levelArr:{} ,// 优先级列表
				levelIndex:'commonly',
				record_id:0 // 需求记录单列id
			}
		},
		computed: {
			selectedMemu(){
				return this.$store.state.selectedMemu
			},
			mId(){
				if(this.selectedMemu.length==3){
					return this.selectedMemu[this.selectedMemu.length - 1].id
				}else{
					return 0;
				}
			}
		},

		async created(){
			this.record_id = this.$route.query.record_id || 0 // 需求记录单列id
			let isRecord = this.$route.query.isRecord || 0 // 转需求标记
			if(isRecord){
				// 填充数据
				this.formState.describe = this.$route.query.describe // 需求描述
			}
			// 获取需求类型
			let res = await this.$utils.api.get({
				url:'/demand_date/selectpage',
				result:1
			})
			if(res.code == 1){
				this.productArr = res.data.list
				this.formState.type = this.productArr[this.stateIndex].id
			}
			// 获取优先级
			let r = await this.$utils.api.get({
				url:'/demand/setting',
				result:1
			})
			if(r.code == 1){
				this.levelArr = r.data.priorityList
				if(isRecord){
					// 填充数据
					this.levelIndex = this.$route.query.priority // 优先级
				}else{
					this.levelIndex = 'commonly' // 默认显示一般
				}
			}
		},
		methods:{
			handleMenuClick(e){
				this.stateIndex = e.key
				this.formState.type = this.productArr[e.key].id
			},
			handleLevelClick(e){
				this.levelIndex = e.key
			},
			async customRequest (e) {
				// 执行图片上传
				let that = this
				let res = await this.$utils.api.upLoad(e)
				if(res){
					setTimeout(()=>{
						that.$utils.msgSuc('上传成功')
						// 上传成功修改文件状态
						that.fileList[that.fileList.length-1].status = 'done' 
						// 图片封面预览
						that.fileList[that.fileList.length-1].thumbUrl = res.data.fullurl 
						// 图片集合
						that.formState.imgs.push(res.data.fullurl)
					},500)
				}
			},
			remove(e){
				// 删除图片
				this.formState.imgs = this.formState.imgs.filter((item) => {
				    return item != e.thumbUrl
				})
			},
			async onFinish(e){
				if(!this.mId){
					this.$utils.msgWar('请在左侧模块菜单选择需求点（第三级）')
					return
				}
				// if(this.formState.imgs.length == 0){
				// 	this.$utils.msgWar('请上传需求图片')
				// 	return
				// }
				
				let info = {
					'row':{
						'date_id': this.formState.type,
						'modular_id':this.mId,
						'describe':this.formState.describe,
						'explain':this.formState.explain,
						'images': this.formState.imgs.length > 0 ? this.formState.imgs.join(',') : '',
						'priority':this.levelIndex
					},
					record_id:this.record_id
				}
				let res = await this.$utils.api.post({
					url:'/demand/add',
					data:info,
					result:1
				})
				if(res.code == 1){
					this.$utils.msgSuc('提交成功');
					this.goBack()
				}
			},
			goBack(){
				 // 返回
				this.$router.back()
			},
			cancel(){
				// 取消/重置数据
				this.stateIndex = 0
				this.formState.type = this.productArr[0].id
				this.formState.describe = ''
				this.formState.explain = ''
				this.formState.imgs = []
				this.fileList = []
			}
		}
	}
</script>

<style lang="scss" scoped>
.container{
	margin: 20px;
	padding: 0;
	overflow: hidden;
	.title{
		width: 100%;
		height: 50px;
		line-height: 50px;
		background-color: #0567a8;
		font-size: 18px;
		font-weight: 700;
		color: #ffffff;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
	.need-box{
		list-style: none;
		padding: 30px 0;
		&:hover{
			&::-webkit-scrollbar {
				width: 6px;
			}
		}
		.btn{
			margin-right: 20px;
		}
	}
	.addimg{
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}
</style>